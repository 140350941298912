



































































































.main {
    margin: 0 5vw;
}

.log-text {
    word-wrap: break-word;
    white-space: pre-wrap;
}

